import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/styles.scss';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import HomePage from './pages/home';
import TermsPage from './pages/tnc';
import SelfRegulationPage from './pages/self-regulation';
import PrivacyPage from './pages/privacy';
import AboutUsPage from './components/aboutUsPage/AboutUsPage';

const root = ReactDOM.createRoot(document.getElementById('root')!);

const router = createBrowserRouter([
  {
    path: '/page',
    element: <HomePage />,
  },
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/terms-of-Use',
    element: <TermsPage />,
  },
  {
    path: '/arre-voice-privacy-policy',
    element: <PrivacyPage />,
  },
  {
    path: '/about-us',
    element: <AboutUsPage />,
  },
  // {
  //   path: '/self-regulation',
  //   element: <SelfRegulationPage />,
  // },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

root.render(<RouterProvider router={router} />);
