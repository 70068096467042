import React, { useEffect, useState } from 'react';
import styles from './NewSlider.module.scss';

const NewSlider = () => {
  // Add state for active slide
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div className={`${styles.main_container}`}>
      <div className={`${styles.slider}`}>
        {/* Update slide components */}
        <div className={`${styles.slide} ${activeSlide === 0? 'active': ''}`}>
          <img src="/assets/images/studioLogo.png" className={`${styles.logo}`} alt="Studio Logo"/>
        </div>
        <div className={`${styles.slide} ${activeSlide === 1? 'active': ''}`}></div>

        <div className={`${styles['slider-nav']}`}>
          <div
            className={`${styles['slider-nav-item']} ${activeSlide === 0 ? 'active' : ''}`}
            onClick={() => setActiveSlide(0)}
          ></div>
          <div
            className={`${styles['slider-nav-item']} ${activeSlide === 1 ? 'active ': ''}`}
            onClick={() => setActiveSlide(1)}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default NewSlider;
