import styles from './VoiceSection.module.scss';
import { VOICE_DATA } from '../../sectionData/VoiceData';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const VoiceScreen = () => {
  const handleRedirectUrl = () => {
    window.open('https://www.arrevoice.com/');
  };
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.logoContainer} cursor-pointer`} onClick={handleRedirectUrl}>
          <div className={`${styles.title}`}>Introducing</div>
          <img className={`${styles.arreVoiceLogo}`} src="/assets/images/arreVoice.svg" alt="arreLogo" />
        </div>
        <div className={`${styles.voiceContainer}`}>
          {VOICE_DATA?.map((item, index) => {
            return (
              <div
                key={index + Math.random()}
                onClick={() => {
                  index === VOICE_DATA.length - 1 && handleRedirectUrl();
                }}
              >
                <div className={`${styles.voiceItem} ${index === VOICE_DATA.length - 1 ? 'cursor-pointer' : ''}`}>
                  <LazyLoadImage loading="lazy" src={item.img} alt={'arre voice'} width={'100%'} height={'100%'} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default VoiceScreen;
