import styles from './AwardsNew.module.scss';
import { AWARD_DATA } from '../../sectionData/AwardData';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AwardsScreen = () => {
  //
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.title}`}>Awards</div>
        <div className={`${styles.awardsContainer}`}>
          {AWARD_DATA?.map((item, index) => {
            return (
              <div key={index + Math.random()}>
                <div className={`${styles.awardItem}`}>
                  <LazyLoadImage loading="lazy" src={item.imgSrc} alt={item.name} width={'100%'} height={'100%'} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AwardsScreen;
