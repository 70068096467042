import styles from './News.module.scss';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { NEWS_DATA } from '../../sectionData/NewsData';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const NewsScreen = () => {
  const handleRedirectUrl = (url: string) => {
    window.open(url);
  };

  return (
    <>
      <div className={`${styles.main_container} `}>
        <div className={`${styles.container}`}>
          <div className={`${styles.logo_container}`}>
            {/* <div className={`${styles.decor_container} `}>
                    </div> */}
            <p>In the news</p>
            {/* <img className={`${styles.logo}`} src="/assets/images/inTheNews.svg" alt="arreLogo" /> */}
          </div>
          <div id="scr-el" className={`${styles.studio_list_container} `}>
            {NEWS_DATA.map((item, index) => {
              return (
                <div key={index}>
                  <div className={`${styles.thumbnail_container}`} onClick={() => handleRedirectUrl(item.url)}>
                    <LazyLoadImage loading="lazy" alt={item.tag} width={'100%'} height={'100%'} src={item.img} />
                    {/* <ImageWithLoader src={item.img} alt={item.tag} /> */}
                    <div className={`${styles.content_container}`}>
                      <h3>{item.tag}</h3>
                      <p>{item.title}</p>
                      <div className={`${styles.read_more}`}>
                        <h5>Read More</h5>
                        <AiOutlineArrowRight className={`${styles.arrow} w-6 h-6`} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default NewsScreen;
