import React from 'react';
import TermsScreen from '../components/tnc/TermsScreen';

interface TermsPageProps {}

const TermsPage: React.FC<TermsPageProps> = () => {
  return <TermsScreen />;
};

export default TermsPage;
