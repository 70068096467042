import Footer from '../common/footer/Footer';
import styles from './AboutUsPage.module.scss';
import { FC } from 'react';
import MemberCard from './MemberCard';
import { IMember, MEMBERS_DATA } from './MembersData';
import NewNavBar from '../common/navBar/NewNavBar';

interface AboutUsPageParams {}

const AboutUsPage: FC<AboutUsPageParams> = () => {
  return (
    <>
      <div className={`${styles.mainContainer}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.navBar}`}>
            <NewNavBar />
          </div>
          <div className={`${styles.contentWrapper}`}>
            <div className={`${styles.header}`}>About Us</div>
            <div className={`m-0 ${styles.aboutUsHighlightTextContainer}`}>
              <div className={`m-0 ${styles.text1}`}>In Every Person Lies A Creator </div>
              <div className={`m-0 ${styles.text1}`}>And in Every Creator an Enterprise</div>
            </div>
            <div className={`${styles.aboutUsTextContainer}`}>
              <div className={`m-0`}>
                Arré is one of India’s leading digital media brands that is home to Arré Studio, where we tell engaging
                and meaningful stories in fiction and non-fiction, through web series, short videos, essays and podcasts
                and Arré Voice, a women-first short audio app where we enable our creators to tell their stories in 30
                second voicepods.
              </div>
              <div className={`m-0`}>
                Through Arré Voice and Arré Studio, Arré is building a monetizable creator ecosystem that brings
                together the best of new and professional creators.
              </div>
            </div>
            {/* <div className={`${styles.teamContainer}`}>
              <div className={`${styles.text3}`}>Meet the Team</div>
              <MemberCard isFounder={true} memberData={MEMBERS_DATA[0]} />
              <div className={`${styles.membersContainer}`}>
                {MEMBERS_DATA?.map((memberData: IMember, index: number) => {
                  if (index === 0 || index === MEMBERS_DATA?.length - 1) {
                    return null;
                  }
                  return (
                    <div key={index + Math.random()}>
                      <MemberCard memberData={memberData} />
                    </div>
                  );
                })}
              </div>
              <MemberCard isFounder={true} memberData={MEMBERS_DATA[MEMBERS_DATA?.length - 1]} />
            </div> */}
          </div>
        </div>
        <div className={`${styles.footerContainer}`}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
