import { useState } from 'react';
import styles from './NewNavBar.module.scss';
import { HashLink } from 'react-router-hash-link';

const NewNavBar = () => {
  const [isShowSideNavbar, setIsShowSideNavbar] = useState(false);
  const contentContainerStyles = isShowSideNavbar ? styles.contentContainerActive : styles.contentContainerInactive;
  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.logo}`}>
          <a href={'/'} rel={'noreferrer'}>
            <img src="/assets/images/arreLogo.svg" width={'100%'} height={'100%'} alt="arreLogo" />
          </a>
        </div>
        <div className={`${styles.menuIcon}`} onClick={() => setIsShowSideNavbar(true)}>
          <img src="/assets/images/menuIcon.svg" width={'100%'} height={'100%'} alt="arreLogo" />
        </div>
        <div className={`${styles.contentContainer} ${contentContainerStyles}`}>
          <div className={`${styles.closeIcon}`} onClick={() => setIsShowSideNavbar(false)}>
            <img src="/assets/images/closeIcon.svg" width={'100%'} height={'100%'} alt="arreLogo" />
          </div>
          <div className={`${styles.navElementsContainer}`}>
            <div className={`${styles.navElement}`}>
              <HashLink
                smooth
                to={'/#home'}
                onClick={() => {
                  setIsShowSideNavbar(false);
                }}
              >
                Home
              </HashLink>
            </div>
            <div className={`${styles.navElement}`}>
              <HashLink
                smooth
                to={'/#studio'}
                onClick={() => {
                  setIsShowSideNavbar(false);
                }}
              >
                Studio
              </HashLink>
            </div>
            <div className={`${styles.navElement}`}>
              <HashLink
                smooth
                to={'/#voice'}
                onClick={() => {
                  setIsShowSideNavbar(false);
                }}
              >
                Voice
              </HashLink>
            </div>
            <div className={`${styles.navElement}`}>
              <HashLink
                smooth
                to={'/#awards'}
                onClick={() => {
                  setIsShowSideNavbar(false);
                }}
              >
                Awards
              </HashLink>
            </div>

            <div className={`${styles.navElement}`}>
              <HashLink
                smooth
                to={'/#news'}
                onClick={() => {
                  setIsShowSideNavbar(false);
                }}
              >
                News
              </HashLink>
            </div>
            <div className={`${styles.navElement}`}>
              <HashLink
                smooth
                to={'/about-us'}
                onClick={() => {
                  setIsShowSideNavbar(false);
                }}
              >
                About Us
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewNavBar;
