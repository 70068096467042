import React from 'react';
import styles from './HomeScreen.module.scss';
import StudioScreen from '../stuioSection/StudioSection';
import VoiceScreen from '../voiceSection/VoiceSection';
import AwardsScreen from '../awardsSection/AwardSection';
import NewsScreen from '../newsSection/NewsSection';
import Coursel from './Coursel';
import Footer from '../common/footer/Footer';
import NewNavBar from '../common/navBar/NewNavBar';
import NewSlider from './NewSlider';

const HomeScreen: React.FC = () => {
  // select the favicon
  const faviconEl: Element = document.querySelector('link[rel="icon"]')!;
  // watch for changes
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  mediaQuery.addEventListener('change', themeChange);
  // listener
  function themeChange(event: MediaQueryListEvent) {
    faviconEl.setAttribute('href', 'https://asset.arredigital.co/Arr%C3%A9%20/favicon.ico');
  }

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={`${styles.navBar}`}>
          <NewNavBar />
        </div>
        <div className={`${styles.bodyContainer}`}>
          <article>
            <section id="home">
              {/* <Coursel /> */}
              <NewSlider/>
            </section>
            <section className={`${styles.section_container}`} id="studio">
              <StudioScreen />
            </section>
            <section className={`${styles.section_container}`} id="voice">
              <VoiceScreen />
            </section>
            <section className={`${styles.section_container}`} id="awards">
              <AwardsScreen />
            </section>
            {/* <section className={`${styles.section_container}`} id="section-four">
            <EssaysScreen/>
          </section> */}
            <section className={`${styles.section_container}`} id="news">
              <NewsScreen />
            </section>
          </article>
        </div>
        <section className={`${styles.footerContainer}`}>
          <Footer />
        </section>
      </div>
    </>
  );
};

export default HomeScreen;
