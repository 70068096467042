import React from 'react';
import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  const handleRedirectUrl = (url: string) => {
    window.open(url);
  };
  return (
    <>
      {/* footer */}
      <div className={`footer-container ${styles.footerContainer}`}>
        <div className={`${styles.footerContent}`}>
          <div className={`${styles.footerSocialIcons}`}>
            <img
              onClick={() => {
                handleRedirectUrl('https://www.instagram.com/arreindia/');
              }}
              className={`${styles.icon}  `}
              alt="Instagram Logo"
              src={'/assets/images/instaLogo.svg'}
            />
            <img
              onClick={() => {
                handleRedirectUrl('https://www.facebook.com/ArreIndia/');
              }}
              className={`${styles.icon}  `}
              alt="Facebook Logo"
              src={'/assets/images/facebookLogo.svg'}
            />
            <img
              onClick={() => {
                handleRedirectUrl('https://x.com/arretweets?s=21&t=bKQ2jRTq_8kChm4DVUXwYQ');
              }}
              className={`${styles.icon}  `}
              alt="Twitter Logo"
              src={'/assets/images/twitterLogo.svg'}
            />
            <img
              onClick={() => {
                handleRedirectUrl('https://www.linkedin.com/company/arr%C3%A9-u-digital-content-pvt-ltd-/mycompany/');
              }}
              className={`${styles.icon} `}
              alt="LinkedIn Logo"
              src={'/assets/images/LinkedInLogo.svg'}
            />
            <img
              onClick={() => {
                handleRedirectUrl('https://www.youtube.com/@ArreIndia');
              }}
              className={`${styles.icon} `}
              alt="Youtube Logo"
              src={'/assets/images/youTubeLogo.svg'}
            />
          </div>
          <p className={`${styles.right_container}`}>@2023 Arré All rights reserved</p>
          <div className={`${styles.privacyContent}`}>
            <div>
              <a
                href="https://www.arre.co.in/terms-of-Use/"
                target={'_blank'}
                rel={'noreferrer'}
                className="hover:underline"
              >
                Terms of Use
              </a>
            </div>
            <div>
              <a
                href="https://www.arre.co.in/arre-voice-privacy-policy/"
                target={'_blank'}
                rel={'noreferrer'}
                className="hover:underline"
              >
                Privacy Policy
              </a>
            </div>
            <div>
              <a
                // href="https://www.arre.co.in/self-regulation/"
                href=" https://arrevoice.notion.site/Self-Regulation-fde7fd3fd1a549ed8ce8c76e52b7f182"
                target={'_blank'}
                rel={'noreferrer'}
                className="hover:underline"
              >
                Self Regulation
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
