import React from 'react';
import HomeScreen from '../components/home/HomeScreen';

interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => {
  return <HomeScreen />;
};

export default HomePage;
