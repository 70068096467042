import React from 'react';
import PrivacyScreen from '../components/privacy/PrivacyScreen';

interface PrivacyPageProps {}

const PrivacyPage: React.FC<PrivacyPageProps> = () => {
  return <PrivacyScreen />;
};

export default PrivacyPage;
