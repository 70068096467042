export const NEWS_DATA = [
    {
        img : "https://admin.indiantelevision.com/sites/default/files/styles/smartcrop_800x800/public/images/tv-images/2024/02/12/mini.jpg?itok=RsPy0DjV",
        title : "Amazon miniTV’s Dillogical explores modern love...",
        tag : "Indian Television" ,
        url : "https://admin.indiantelevision.com/iworld/over-the-top-services/amazon-minitv%E2%80%99s-dillogical-explores-modern-love-in-the-realm-of-open-relationships-240212"              
    },
    {
      img : "/assets/images/news3.svg",
      title : "An Interaction With Niyati Merchant, Co-Founder Arré...",
      tag : "Economic Times" ,
      url : "https://economictimes.indiatimes.com/industry/media/entertainment/arr-starts-project-voice-to-boost-creator-ecosystem/articleshow/92367733.cms?from=mdr"              
  },
    {
        img : "https://www.csrmandate.org/wp-content/uploads/2023/01/Arre-Voice-and-Akshara-Centre-partner-to-drive-Conversation-Awareness-around-Safety-for-Women3.png",
        title : "Arré Voice & Akshara Centre Drive Conversation...",
        tag : "Csr Mandate",
        url : "https://www.csrmandate.org/arre-voice-and-akshara-centre-drive-conversation-and-awareness-around-safety-for-women/"
    },
    {
        img : "https://cdn.wan-ifra.org/wp-content/uploads/2020/04/24135113/Netflix-father-Illustration_2_guefk1.jpg",
        title : "How Arré navigated its way into India’s homegrown OTT...",
        tag : "wan-ifra",
        url : "https://wan-ifra.org/2019/12/how-arre-navigated-its-way-into-indias-homegrown-ott-space/"
    },
    {
      img : "https://etimg.etb2bimg.com/photo/96199339.cms",
      title : "Endemol Shine's Anaya Mohanty joins Arre Studio...",
      tag : "brand equity",
      url : "https://brandequity.economictimes.indiatimes.com/news/the-people-report/endemol-shines-anaya-mohanty-joins-arre-studio-as-creative-director/96199105"
    },
    {
      img : "https://image.telanganatoday.com/wp-content/uploads/2023/06/tr-9_V_jpg--816x480-4g.webp?sw=1440&dsz=816x480&iw=659&p=false&r=2",
      title : "Arthamainda Arun Kumar’, a captivating tale of an intern...",
      tag : "telangana today",
      url : "https://telanganatoday.com/aha-unveils-trailer-of-arthamainda-arun-kumar-a-captivating-tale-of-an-intern"
    },
    {
      img : "https://bestmediainfo.com/uploads/2022/04/MIA-Key-Art_English_9.jpg",
      title : "crime thriller Murder in Agonda...",
      tag : "Best Media Info",
      url : "https://bestmediainfo.com/2022/04/amazon-minitv-announces-premiere-of-their-crime-thriller-murder-in-agonda"
    },
    {
      img : "https://exchange4media.gumlet.io/news-photo/117307-big4.jpg?format=webp&w=768&dpr=2.0",
      title : "'Sorry Bhaisaab' on December 16... ",
      tag : "exchange4media",
      url : "https://www.exchange4media.com/digital-news/amazon-minitv-to-premiere-short-film-sorry-bhaisaab-on-december-16-117307.html"
    }
    
]