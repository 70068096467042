import styles from './Studio.module.scss';
import { FaPlay } from 'react-icons/fa';
import { STUDIO_DATA } from '../../sectionData/StudioData';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const StudioScreen = () => {
  const handleRedirectUrl = (url: string) => {
    window.open(url);
  };
  return (
    <>
      <div className={`${styles.main_container} `}>
        <div className={`${styles.container}`}>
          <div className={`${styles.logo_container}`}>
            <div className={`${styles.decor_container} `}></div>
            <img className={`${styles.logo}`} src="/assets/images/studioLogo.svg" alt="arreLogo" />
          </div>
          <div className={`${styles.padding_container}`}>
            <ul className={`${styles.studio_list_container} `}>
              {STUDIO_DATA.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={`${styles.thumbnail_container}`} onClick={() => handleRedirectUrl(item.url)}>
                      <div className={`${styles.thumbnail_img}`}>
                        <LazyLoadImage
                          loading="lazy"
                          alt={item.title}
                          width={'100%'}
                          height={'100%'}
                          src={item.thumbail}
                        />
                        {/* <ImageWithLoader src={item.thumbail} alt={item.title} /> */}
                      </div>
                      <div className={`${styles.play_btn}`}>
                        <FaPlay />
                      </div>

                      <p>{item.title}</p>
                    </div>
                  </div>
                );
              })}
            </ul>
          </div>

          {/* <button className={`${styles.btn_container}`}
              onClick={() => handleRedirectUrl("https://www.youtube.com/channel/UC2O-N1R4x56XhndL4qqfKcw")}
            >Go to Youtube Channel</button>
          */}
        </div>
      </div>
    </>
  );
};

export default StudioScreen;
