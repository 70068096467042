export const STUDIO_DATA = [
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/dillogical.png',
    title: 'Dil Logical',
    url: 'https://www.youtube.com/watch?v=uG1-2JxpiBw',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/chukiyaSeries.jpg',
    title: 'Official Chukyagiri',
    url: 'https://www.youtube.com/watch?v=FJ6jPqWz5FQ&t=2s',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/sorryBhaisaab.jpg',
    title: 'Sorry Bhaisaab',
    url: 'https://www.youtube.com/watch?v=kVx1Kjpnc3M',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/aisha.jpg',
    title: 'A.I.SHA Season 3',
    url: 'https://www.youtube.com/watch?v=Zjym5tAsGaw&list=PLcvCQa6KHYRm24lx9_etBdw-rmeGUOkrR',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/transistor.jpg',
    title: 'Transistor',
    url: 'https://www.youtube.com/watch?v=44MTFi4Mv9k&t=1s',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/IDWTVSeries.jpg',
    title: "I Don't watch TV",
    url: 'https://www.youtube.com/watch?v=8T6uORDEdB0&t=2s',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/theRealHighFeed.jpg',
    title: 'The Real High',
    url: 'https://www.youtube.com/watch?v=W9rZg13WUZ8&t=10s',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/arushi.jpg',
    title: 'Trial By Error',
    url: 'https://www.youtube.com/watch?v=c5Fc6dO3UaY',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/1962.jpg',
    title: '1962',
    url: 'https://www.youtube.com/watch?v=BAvSzDT1aAw',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/pariwar.jpg',
    title: 'Pariwar',
    url: 'https://www.youtube.com/watch?v=jJT_p66Eo_g&t=1s',
  },
  {
    thumbail: 'https://asset.arredigital.co/Arr%C3%A9%20/images/studio/monica.jpg',
    title: 'Main Monica',
    url: 'https://www.youtube.com/watch?v=MUrQm7bLHvU&t=1s',
  },
];
