export const AWARD_DATA = [
  {
    name: 'Asian Award',
    imgSrc: 'https://asset.arredigital.co/Arr%C3%A9+/images/awards/asianAward.png',
  },
  {
    name: 'South Florida Award',
    imgSrc: 'https://asset.arredigital.co/Arr%C3%A9+/images/awards/southFloridaAward.png',
  },
  {
    name: 'Wan Infra Award',
    imgSrc: 'https://asset.arredigital.co/Arr%C3%A9+/images/awards/wanInfraAward.png',
  },
  {
    name: 'Cmo Asia Award',
    imgSrc: 'https://asset.arredigital.co/Arr%C3%A9+/images/awards/cmoAsiaAward.png',
  },
  {
    name: 'Washington Roman Pictures Award',
    imgSrc: 'https://asset.arredigital.co/Arr%C3%A9+/images/awards/romanPicturesAward.png',
  },
  {
    name: 'LA Web Festival Award',
    imgSrc: 'https://asset.arredigital.co/Arr%C3%A9+/images/awards/laWebAward.png',
  },
];
