import React from 'react';
import styles from './TermsScreen.module.scss';
import Footer from '../common/footer/Footer';

const TermsScreen: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={`${styles.header}`}>
        <div className={`${styles.headerLogo}`}>
          <a href="/" className="cursor-pointer">
            <img alt="arreLogo.img" src={'/assets/images/arreLogo.svg'} width={'100%'} height={'100%'} />
          </a>
        </div>
      </div>
      <div className={`${styles.contentWrapper}`}>
        <div className={`${styles.title} underline`}>TERMS AND CONDITIONS</div>
        <div className={`${styles.lastUpdatedText}`}>Last Updated on 06 September 2022</div>
        <div className={`${styles.text1}`}>
          This is an electronic record in accordance with Information Technology Act, 2000 (as may be amended, modified,
          re-enacted, consolidated or replaced from time to time) and rules thereunder pertaining to electronic records
          as applicable and amended from time to time. This document is generated by a computer system and does not
          require any physical or electronic signature.
        </div>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>1.</div>
          <div>PREAMBLE </div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            <span className={`${styles.text1Bold}`}>U Digital Content Private Limited </span> is a company duly
            organized under the laws of India and engaged, inter alia, in the business of providing an online, Women
            (defined hereinafter)first, audio based streaming and social networking platform under the brand and style
            name of Arré Voice (hereinafter referred to as{' '}
            <span className={`${styles.text1Bold}`}>“Company”, “we”, “us”, “our”</span>) which shall provide Users
            (defined hereinafter) the means to be heard and to drive conversations, community and commerce. The Company
            will provide the online Platform (hereinafter defined) to Users all over the world through its website
            available at{' '}
            <a className={`${styles.hyperLink}`} href="https://www.arrevoice.com/" target="_blank" rel="noreferrer">
              www.arrevoice.com
            </a>{' '}
            (<span className={`${styles.text1Bold}`}>"Website”</span>) and mobile application(s) for Android and iOS
            devices under the name Arré Voice (<span className={`${styles.text1Bold}`}>“Application”</span>). The
            Website and the Application will be jointly known as{' '}
            <span className={`${styles.text1Bold}`}>“Platform”</span>.
          </li>
          <li>
            The Users of the Platform may create and contribute to audio-based content in multiple languages, browse
            through the content through the available categories and tags, and may monetize passion, products and
            services by availing the features and functionalities of the Platform in accordance with the following Terms
            and Conditions. The Company’s role is limited to the managing Application and associated marketing,
            facilitating payment collections, fulfilment, order management, enquiry management and other incidental
            services to enable the transactions between the Users (
            <span className={`${styles.text1Bold}`}>"Services"</span>).
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>2.</div>
          <div>INTRODUCTION </div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            These Terms, as may be amended from time to time, apply to all our Services directly or indirectly made
            available online, through this Platform. In addition, when using certain services or features on the
            Platform, You may be subject to any additional terms applicable to such services that may be posted on the
            Platform from time to time, including, without limitation, the Privacy Policy available at the Platform and
            any other code of conduct or general practices.
          </li>
          <li>
            Please read these terms and conditions (<span className={`${styles.text1Bold}`}>"Terms"</span>) carefully
            before accessing or using the Platform (defined hereinafter). These Terms along with the Privacy Policy
            published on the Platform (<span className={`${styles.text1Bold}`}>"Privacy Policy"</span>) and other
            policies (as may be notified/displayed/published on the Platform) constitutes the contract between the Users
            of this Platform and Company (collectively <span className={`${styles.text1Bold}`}>"Agreement"</span>). By
            use of the Platform, Users agree to be bound by these Agreement as posted on the Platform from time to time.
          </li>
          <li>
            Any usage of the Platform in any manner whatsoever shall be governed by these Terms. If You do not want to
            be bound by these Terms, You must not retain, access or use the Platform in any manner whatsoever. Your
            agreement to these Terms shall indicate that You have the right, authority and legal capacity to agree to
            the Terms and that You have read, understood and agree to be bound by the same with respect to Yourself and
            any minor in Your care who has access to our Services. Anything done, caused to be done, whether expressly
            or impliedly in contravention of these Terms may render You liable for legal action.
          </li>
          <li>
            We reserve the right, at our sole discretion, to change, modify, add or remove portions of these Terms, at
            any time without any prior written notice to You. It is Your responsibility to review these Terms
            periodically for updates / changes. Your continued use of the Platform following the posting of changes will
            mean that You accept and agree to the revisions. As long as You comply with these Terms, we grant You a
            personal, non-exclusive, non-transferable, limited privilege to enter and use the Platform and register and
            access the Services.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>3.</div>
          <div>DEFINITIONS </div>
        </div>
        <div className={`${styles.text1}`}>
          In addition to the terms defined in the text of these Terms, whenever used in these Terms, unless repugnant to
          the meaning or context thereof, the following words and expressions shall have the following meanings:
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            <span className={`${styles.text1Bold}`}>“Account”</span> means the virtual profile a User creates when the
            User successfully signs up / registers on the Platform and includes User related information collected while
            using the Platform and accessing the Services via such virtual profile including browsing history on the
            Platform, User participation and posts and Platform features or Services accessed, payment related details,
            etc.;
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“Agreement”</span> shall have the meaning ascribed to the term under
            Section 18.1;
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“Business Partners”</span> means a Person with whom the Company or
            the Entities have a contractual relationship for the purpose of their business including merchants,
            advertisers, deal partners, financial institutions and other business partners.
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“Entities”</span> means the group, affiliates and subsidiaries of
            the Company;
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“Intellectual Property”</span> means any invention (whether
            patentable or unpatentable and whether or not reduced to practice), any improvement thereto, and any patent,
            patent application, and patent disclosure, together with any reissuance, continuation, continuation-in-part,
            revision, extension, and re-examination thereof; any trademark, service mark, trade dress, logo, trade name,
            and corporate name, together with any translation, adaptation, derivation, and combination thereof and
            including any goodwill associated therewith, and any application, registration, and renewal in connection
            therewith; any copyrightable work, any copyright, and any application, registration, and renewal in
            connection therewith; any mask works and any application, registrations, and renewals in connection
            therewith; any trade secret and confidential business information (including any idea, research and
            development, know-how, formula, compositions, manufacturing and production process and technique, technical
            data, technical know-how design, drawing, specification, customer and supplier lists, pricing and cost
            information, and business and marketing plans and proposals); any computer software (including artificial
            intelligence, chatbot, data and related documentation), databases, programming, codes and schemas; any other
            proprietary right; any copies and tangible embodiments thereof (in whatever form or medium); any license or
            sublicense of an Intellectual Property right, whether exclusive or non-exclusive to us; internet domain name
            registrations and rights; and any software, features, design, programming, application, development work
            and/or promotion, advertising which in any way contributes/supports, tests, helps the business of the
            Company whether developed by a third party or employees of the Company or outsourced by us;
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“Person(s)”</span> shall mean any individual, sole proprietorship,
            unincorporated association, body corporate, corporation, company, partnership, limited liability company,
            joint venture, Governmental Authority, trust or any other entity or organization;
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“Platform”</span> shall include the website and mobile
            applications(s) referred to under Section 1.1 along with website(s), mobile application(s), devices, links,
            notifications, artificial intelligence, software, chatbot, or any other medium owned or used by the Company
            and Entities to provide the Services to Users;
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“User”, “You”, “Your”</span> means the relevant Woman who has signed
            up / registered for the Services provided by the Platform and/or has access to/uses the features and
            functionalities of the Platform and who in connection with the Platform and the Services may access or avail
            or host, publish, share, transact, view, display, download or upload any information on the Platform;
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“Service Providers”</span> means a party whose services are used by
            the Company or the Entities in order to provide the intended Services to You through / on the Platform; and
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“Services”</span> shall have the meaning collectively ascribed to
            the term under Section 1.2;
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>“Woman”</span> means a woman and any person who identifies or
            relates to themselves as a woman.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>4.</div>
          <div>SERVICES </div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            Your right to use the Services is subject to the limitations, conditions, and restrictions established by us
            from time to time, at our sole discretion. You must provide certain devices, software, and data
            connection(s) to use our Services, which we otherwise do not supply. For as long as You use our Services,
            You consent to downloading and installing updates to our Services, including automatically. We may alter,
            suspend, or discontinue the Services at any time, without any prior intimation to the User. We may also
            impose limits on certain features and aspects of the Services or restrict Your access to certain parts of
            the Services or all of the Services and have the right to modify or discontinue, temporarily or permanently,
            the Services (or any part thereof) with or without notice, without any intimation and liability on us,
            either to You or to any third party.
          </li>
          <li>
            By using the Platform, You acknowledge that while we take every reasonable action to make sure that the
            Services remain fully functional and up to date, interruptions do happen, for any reason or no reason,
            including for routine maintenance, as may be required for effective functioning of the Platform. You
            understand and acknowledge that the Services could be interrupted, suspended, or terminated due to any
            reason whatsoever, without any fault of ours and therefore we shall not be held liable in any manner
            whatsoever, for any loss to anyone due to such interruption, suspension or termination of Services without
            any fault of ours.
          </li>
          <li>
            You acknowledge that the Company may establish and update, in its sole discretion, these Terms, the Privacy
            Policy, code of conduct and general practices concerning use of the Platform and Services at any time, with
            or without notice, including without limitation the maximum period of time that data or other content will
            be retained by the Company. You agree that the Company has no responsibility or liability for the deletion
            or failure to store any data or other content maintained or uploaded on the Platform or provided through use
            of the Services.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>5.</div>
          <div>ELIGIBILITY AND GENERAL CONDITIONS</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            You may be required to register as a User on the Platform in order to access and use certain features and
            functionality of the Platform and Services. Any Woman is eligible to register on the Platform in the manner
            provided herein. If You choose to register on the Platform and make use of any of the Services, You agree to
            provide and maintain true, accurate, current and complete information about Yourself as prompted by at the
            time of registration.  All such information shall be collected and governed in accordance with our Privacy
            Policy (link provided hereinabove).
          </li>
          <li>
            You must be at least 18 (eighteen) years of age and / or have attained the prescribed age of lawful majority
            under the legal jurisdiction to which You are subject and possess the legal authority to enter into an
            agreement so to register as User and use the Platform and / or Services. A person who has previously been
            registered as a User but whose User account was disabled by us for any violation of the Agreement will not
            be eligible to register as a User.
          </li>
          <li>
            Your conduct on the Platform is subject to the laws, regulations, and rules of applicable authorities. You
            agree that You are not prohibited from using our Services under the laws applicable to You in any manner. We
            reserve the right to terminate a User’s account and / or block access of a User to User’s account on this
            Platform and the Services if it is discovered that You are a minor or incompetent to contract according to
            the applicable law or any information pertaining to Your age or eligibility entered at the time of creation
            of Your Account with on the Platform is false and for other reasons in our sole discretion, including if a
            User’s Account is inactive for an extended period of time.
          </li>
          <li>
            You are responsible for maintaining the confidentiality of Your login details (such as Your username,
            passwords and related information used or submitted by You to access certain functionalities of the
            Platform) and Your Account. You agree to (a) immediately notify us of any unauthorized use of the same or
            any other breach of security, and (b) ensure that You exit from Your Account at the end of each session when
            accessing the Service. We will not be liable for any loss or damage arising from Your failure to comply with
            this. You fully acknowledge and understand that You shall be solely liable and responsible for all the
            activities undertaken under Your Account and any consequences therefrom.
          </li>
          <li>
            To the extent You access Services through a mobile device, Your wireless service carrier’s standard charges,
            data rates and other fees may apply. In addition, downloading, installing, or using certain Services or
            features of the Platform through a mobile may be prohibited or restricted by Your carrier, and not all such
            services or features may work with all carriers or devices. By using the Services through mobile, You agree
            that we may communicate with You regarding the Company and Entities by text message or other electronic
            means to Your mobile device and that certain information about Your usage of the Services and Platform and
            Your device will be available to us and we may store certain information, including on Your device for the
            purpose of this Agreement. If there is any change to Your contact number, You agree to promptly update Your
            Account information to ensure Your communication is not sent to another person.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>6.</div>
          <div>CONTENT POLICY</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            As User, You hereby grant us a non-exclusive, fully paid and royalty-free, worldwide, limited license in
            respect of any manner of content generated by the User on or through our Platform or our Services, including
            to display and publish, monetise such content as felt appropriate, in any media format(s) through any media
            channels, and to delete such content. You also grant the Company, the Entities and our Business Partners the
            unrestricted, worldwide, perpetual right and license to use Your name, likeness, and voice with respect to
            such content. We do not claim any ownership or modification or derivative rights in any such content or to
            the underlying works in respect of such content.
          </li>
          <li>
            You agree that Your use of the Platform and / or Services will not grant You any right to any compensation
            in any form, including any share in revenue or value from the Company, the Entities, or our Business
            Partners. You will not be entitled to any compensation from the Company, the Entities, or our Business
            Partners.
          </li>
          <li>
            You acknowledge that the Company may record and save all or any of the audio content submitted by Users on
            the Platform and may make use of the same and other content in accordance with the Agreement between us or
            for the purpose of ensuring compliance with any prevailing laws. If You are not comfortable with us
            recording the audio content or using the content submitted on the Platform, please refrain from submitting
            the same. The Company may make certain audio and other content available for export from the Platform for
            sharing on other online platforms, subject to the inclusion of a Platform voice stamp or mark. With respect
            to audio content created through use of our Services and exported from the Platform,{' '}
          </li>
          <li>
            You will be solely responsible for all audio, code, video, images, information, data, text, software, music,
            sound, photographs, graphics, messages or other materials (
            <span className={`${styles.text1Bold}`}>“content”</span>) that You upload, post, publish or display
            (hereinafter, <span className={`${styles.text1Bold}`}>“upload”</span>) or email or otherwise use via the
            Service. You represent and warrant that You own all the content generated by the User on or through our
            Platform or our Services or upload of the Platform, or otherwise have the right to grant the license set
            forth in this Section, and posting and use and uploading of Your content on or through our Platform or our
            Services does not: violate the contractual rights, intellectual property rights or any other rights of any
            person, result in a breach of contract between You and a third party and does not violate any applicable
            laws and regulation or any code of conduct or general practices available on the Platform.
          </li>
          <li>
            <ol style={{ listStyleType: 'lower-roman' }} className={`${styles.text1}`}>
              You agree that You shall not host, display, upload, modify, publish, transmit, update or share any
              information or content on the Platform and / or through use of our Services that:
              <li>
                You agree that You shall not host, display, upload, modify, publish, transmit, update or share any
                information or content on the Platform and / or through use of our Services that:
              </li>
              <li>
                is defamatory, obscene, pornographic, paedophilic, invasive of another's privacy, including bodily
                privacy, insulting or harassing on the basis of gender and sexual preference, libellous, racially or
                ethnically objectionable, relating to violence or terrorism, pertains to speeches towards hatred or bias
                towards religion or person, relating or encouraging money laundering or gambling, or otherwise
                inconsistent with or contrary to the laws in force;
              </li>
              <li>is harmful to minors in any way;</li>
              <li>
                infringes any patent, trademark, copyright or other proprietary rights or third party’s trade secrets or
                rights of publicity or privacy or is fraudulent or involves the sale of counterfeit or stolen items;
              </li>
              <li>violates any law for the time being in force;</li>
              <li>
                knowingly and intentionally communicates any information which is patently false or misleading in nature
                but may reasonably be perceived as a fact or which deceives or misleads the addressee about the origin
                of such messages or communicates any information which is grossly offensive or menacing in nature;
              </li>
              <li>impersonate another person or use an anonymous proxy;</li>
              <li>solicit personal information from anyone under the age of 18;</li>
              <li>
                threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with
                foreign nations, or public order, or causes incitement to the commission of any cognizable offence or
                prevents investigation of any offence or is insulting other nations;
              </li>
              <li>
                use or collect email addresses or other contact information of other Users from the Service/ Platform by
                electronic or other means for the purposes of sending unsolicited emails or other unsolicited
                communications or unauthorized communication;
              </li>
              <li>
                contains software viruses or any other computer code, files or programs designed to interrupt, destroy
                or limit the functionality of any computer resource;
              </li>
              <li>
                is patently false and untrue, and is written or published in any form, with the intent to mislead or
                harass a person, entity or agency for financial gain or to cause any injury to any person;
              </li>
              <li>would result in the invasion and/or breach of privacy of any Person;</li>
              <li>
                may give rise to liability on part of the Company or cause the Company any hindrance (in whole or in
                part);
              </li>
              <li>
                impersonate any person or entity, or falsely state or otherwise misrepresent your association or
                affiliation with a person or entity;
              </li>
              <li>
                advertise or offer to sell or buy any goods or services for any business purpose that is not
                specifically authorized or illegal in nature;
              </li>
              <li>
                promote or aid in the building of a competitive product or service, copy the Platform’s features or user
                interface, or solicit users or customers from the Platform ;
              </li>
              <li>
                engage in any conversation or otherwise upload any content that (i) contains software viruses or any
                other computer code, files or programs designed to interrupt, destroy or limit the functionality of any
                computer software or hardware or telecommunications equipment; (ii) poses or creates a privacy or
                security risk to any person; (v) constitutes unsolicited or unauthorized advertising, promotional
                materials, commercial activities and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,”
                “contests,” “sweepstakes,” or any other form of solicitation; (iv) is unlawful, harmful, threatening,
                abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous,
                invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable; or (v) in the
                sole discretion of the Company is objectionable or which restricts or inhibits any other person from
                using or enjoying the Platform or any of the Services, or which may expose the Company and/ or its users
                to any harm or liability of any type;
              </li>
              <li>
                further or promote any criminal activity or enterprise or provide instructional information about
                illegal activities;
              </li>
              <li>
                obtain or attempt to access or otherwise obtain any materials or information through any means not
                intentionally made available or provided for through the Platform;
              </li>
              <li>
                abstract, download, store, reproduce, transmit, display, copy, distribute or use the contents available
                on the Platform other than as permitted in these Terms, including but not limited to sending any
                unsolicited promotional or advertising material or messages.
              </li>
            </ol>
          </li>
          <div className={`${styles.text1}`}>
            You shall not use any device, program, algorithm or methodology, or any manual or digital process, to
            access, acquire, copy or monitor any portion of the Platform or any content on the Platform to which you
            have no rights, or in any way reproduce or circumvent the navigational structure or presentation of the
            Platform or any such content, to obtain or attempt to obtain any materials, documents or information through
            any means not purposely made available through the Platform.
          </div>
          <div className={`${styles.text1}`}>
            You acknowledge and agree that the content uploaded is the sole responsibility of the User who has uploaded
            such content and the views expressed are their own. You shall be solely responsible for the content posted,
            shared, modified uploaded, transmitted, updated and hosted by You. The Company is not responsible for the
            content posted by the Users, or any consequences thereof.
          </div>
          <li>
            We shall not host, upload, store or publish and do not permit our Users to publish any unlawful information
            which is prohibited under any law for the time being in force including in relation to the interest of
            sovereignty and integrity of any country, security of the State, friendly relations with foreign states,
            public order, decency or morality, in relation to contempt of court, defamation, incitement to an offence
            relating to the above or any information which is prohibited under any law for the time being in force. Upon
            actual knowledge any such information is being hosted, we shall have the right to remove or disable access
            to that information.
          </li>
          <li>
            In case of any violation of these Terms, the Company has the right to take appropriate action, both against
            the User and in respect of content posted by the User. You shall be given reasonable and adequate
            opportunity to dispute the action being taken by addressing Your requests and complaints to our Grievance
            Officer in accordance with our Grievance Redressal Mechanism (detailed hereinafter).
          </li>
          <li>
            You acknowledge and agree that (i) we may also share any information in our control or possession, to
            appropriate authorities for the purpose of verification of identity or for the prevention, detection,
            investigation or prosecution of offences under any law for the time being or for cyber security incidents
            and (ii) we may preserve such information and associated records for a minimum of 180 (one hundred and
            eighty) days or longer as maybe required for evidentiary and investigation purposes.
          </li>
          <li>
            We intend to give Our Users the freedom to express themselves without the fear of emotional or psychological
            distress. We urge you to ignore any content that you may find petty and annoying. In addition to this, we
            also encourage you to report any such content that harasses another person or intends to degrade or shame
            any individual. Content that are found to violate the Terms, the Privacy Policy as well as the provisions of
            applicable laws will be reported to appropriate authorities.
          </li>
          <li>
            The contents and activities that may be termed as against the community guidelines can be:
            <ol style={{ listStyleType: 'lower-alpha' }} className={`${styles.text1}`}>
              <li>
                Using or sharing abusive language or gender-caste-class-race specific curse words, morphed images,
                and/or malicious recordings.
              </li>
              <li>
                Objectifying, insulting or harassing someone based on their race, caste, colour, disabilities, religion,
                sexual preferences and /or making sexual advances or otherwise engaging in sexual misconduct 
              </li>
              <li>
                Creating multiple accounts, or wrongful usage of other accounts to harass users who have blocked you and
                do not wish to engage with you further.
              </li>
              <li>
                Acting pertaining to but not limited to phishing, hacking, doxing, catfishing or other forms of
                impersonation, 
              </li>
              <li>
                Spreading false information posted to harass someone for financial gain, or cause any injury to them
              </li>
              <li>
                Sending viruses or other forms of malwares to extract sensitive personal information or cause harm to
                the users
              </li>
            </ol>
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>7.</div>
          <div>ROLES, RIGHTS AND OBLIGATIONS OF USERS</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            On our Platform, we aim to create a safe and non-judgmental space for a Woman. We will not tolerate any
            manner of bullying or harassment by the Users, Women, Person, Services Providers or Business Partners on the
            Platform. We accordingly impose certain obligations and offer various roles, rights and functionalities to
            the Users in respect of the content and User’s engagement on the Platform, inclusive but not limited to the
            following. Users shall be granted access to the features and functionalities available on the Platform as
            more detailed in the FAQs section, subject to their privacy settings:
          </li>
          <li>
            All content available on our Platform shall be indexed and may be viewed by category, topic and further may
            be tagged and filtered through the use of appropriate hashtag(s). User’s may make use of these features in
            an appropriate manner to distinguish their content and to browse and peruse content on the Platform.
          </li>
          <li>
            When any User is engaging in any conversation or discussion or collaborations or payments on the Platform or
            through use of the Services in the manner described in this Section 7, the User agrees to always abide by
            the provisions of this Section and the Agreement or be solely responsible for consequences of breach
            thereof. The liability regarding any views, expressions, opinions, comments, etc. expressed or communicated
            by Users and / or moderators is personal in nature and the liability as regards such views, expressions,
            opinions, comments, etc. shall solely be that of the User and / or moderator respectively. The Company shall
            not be liable, in any way, in respect of the same.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>8.</div>
          <div>SIGN UP / REGISTRATION</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            To register an Account with us, an applicant will require an invite from the app or any of its existing
            users and additionally be required to register and fill an online form on the Platform, by providing certain
            essential details as may be required by us, including for the purpose of verification as to Your identity
            for instance, as to whether the applicant is a Womannot. Upon registration and at the time of registration,
            You will be required to keep Your Account related information complete and updated at all times. Users shall
            be granted access to the Platform and Services and features and functionalities thereof in the manner
            provided in the Agreement. We expect that You would register Your Account with fairness and honesty.
            However, You have no obligation to register an Account or to use our Services.
          </li>
          <li>
            You hereby warrant that the information provided by You to the Company for the purpose of registration and
            for use of the Platform and Services shall be true, accurate and correct for all required elements. The
            information we request may include certain personal information and sensitive personal information of the
            User as detailed in our Privacy Policy (linked hereinabove).
          </li>
          <li>
            All information and other details filled by You shall undergo a review process by us and we reserve the
            right to seek such additional details as may be required. During such review process, we reserve the right
            to suspend or cancel any Account if we have a reasonable doubt that the Account does not meet the required
            standards set by us. We shall not be liable for any injury, damage or other consequence, fraud related or
            otherwise arising out of any inaccuracy in the information provided by You on the Platform at any point. The
            Company reserves the right to accept or reject Your application to register for any reason.
          </li>
          <li>
            If at any point of time we come to know or have reasons to believe that any information provided by You is
            untrue, inaccurate, or incomplete then we have full right to promptly cancel, terminate or suspend Your
            Account, access to the Platform and / or Services (partly or wholly) without any notice. Further, we reserve
            and may exercise such rights also in the event we have any reason to believe that You have committed a
            breach of the Agreement.
          </li>
          <li>
            We do not collect any fees for registration and use of our Platform and / or the Services. However, User’s
            acknowledge and accept that the Company shall collect a commission fee on all payments and transactions
            conducted on the Platform including through the use of the Services including amounts paid in respect of
            Gigs and as tips.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>9.</div>
          <div>SECURITY AND USER INFORMATION</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            You are responsible for maintaining the confidentiality and security of Your Account and any and all Account
            and User related information that You may use to access the Services and / or the Platform. It is reiterated
            that You are responsible for (a) keeping Your Account and all associated information up to date; and (b)
            maintaining the confidentiality of Your User information and the security of Your Account, which includes
            the enabling of all relevant security features. You shall be responsible for all the activities that occur
            under Your Account. You will not sell or otherwise transfer Your Account to another person or entity without
            our prior written permission. You agree to notify us immediately if You become aware of any unauthorized use
            of the Platform and / or Services and any other breach of security regarding the Services. We will not be
            liable for any loss or damage arising from Your failure to protect Your Account or Your User information.
          </li>
          <li>
            We shall not bear any liability for any damage or interruptions caused by any computer viruses, spyware, or
            other malware that may affect Your computer or other equipment, or any phishing, spoofing, or other attack
            or any breach of Your data owing to Your negligence. We advise that (i) You regularly use a reliable virus
            and malware screening and prevention software; (ii) In the event You question the authenticity of a
            communication purporting to be from the Company, You may contact us in the manner provided in these Terms
            and (iii) You should login to Your Account only through the Platform, and not by clicking links contained in
            emails.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>10.</div>
          <div>OFFERS</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            The Company or Entities may invite You to participate in promotional offer(s) from time to time. You agree
            that participating in such an offer is subject to Your agreement to the respective offer terms and
            conditions. You also understand that the offers might be provided by third parties including Business
            Partners and Service Providers on the Platform and You may be required to agree to respective terms and
            conditions of third parties. You further agree that offers provided to any User may vary from User to User.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>11.</div>
          <div>ROLE AND USAGE WITH RESPECT TO PRODUCTS AND SERVICES</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            The User agrees, understands and acknowledges that the Platform is an online platform that enables You to
            view and avail listed products and services at the indicated price. You further agree and acknowledge that
            the Platform is only a facilitator and is not and cannot be a party to or control in any manner any
            transactions. Accordingly, the contract of sale of products and/or services on the Platform shall be a
            bipartite contract between You and the merchants/sellers (
            <span className={`${styles.text1Bold}`}>“Merchants”</span>) on the Platform. Any purchase of a product
            and/or service listed on the Platform shall be subject to the additional specific terms and conditions
            provided on the concerned product/ service listing page.
          </li>
          <li>
            The User agrees and undertakes not to sell, trade, resell or exploit for any commercial purposes, any
            portion of the services or product purchased via the Platform. The User further agrees and undertakes not to
            copy, distribute, transmit, display, perform, reproduce, publish, license, reverse engineer, create
            derivative works from, transfer, or sell any information, software, products, services or intellectual
            property obtained from the Platform in any manner whatsoever.
          </li>
          <li>
            You acknowledge and undertake that You are viewing/accessing the products and services on the Platform at
            your own risk and are using Your best and prudent judgment before entering into any transactions on the
            Platform. We shall neither be liable nor responsible for any actions or inactions of sellers/service
            providers nor any breach of conditions, representations or warranties by the sellers, manufacturers or
            service providers of the products/services and hereby expressly disclaim and any all responsibility and
            liability in that regard. We shall not mediate or resolve any dispute or disagreement between You and the
            sellers, manufacturers, service providers of the products/services.
          </li>
          <li>
            We further expressly disclaim any warranties or representations (express or implied) in respect of quality,
            suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness
            for a particular purpose, or legality of the products/services listed or displayed or transacted or the
            content (including product/service or pricing information and/or specifications) on the Platform. While We
            have taken precautions to avoid inaccuracies in content, the Platform, all content, information (including
            the price of products/services), software, products, services and related graphics are provided as is,
            without warranty of any kind. We do not implicitly or explicitly support or endorse the sale or purchase of
            any products/services on the Platform. At no time shall any right, title or interest in the
            products/services purchased/availed through or displayed on the Platform vest with Us nor shall We have any
            obligations or liabilities in respect of any transactions on the Platform.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>12.</div>
          <div>COMMUNICATION</div>
        </div>
        <div className={`${styles.text1}`}>
          The Company and the Entities may communicate with You on the contact information that You may have provided to
          us, including but not limited to for purposes of User verification, offers, registration, material or other
          changes to these Terms or Services, transactional or payment related communication or for advertisement and
          promotional purposes and for availing any third-party products or services on the Platform and as may be
          required to give notice under applicable laws.
        </div>
        <div className={`${styles.text1}`}>
          We will send You communication alerts via emails or texts or push notifications or via other progressive
          technology. You also agree that there can be disruption in communications due to factors that are not under
          Our control, including but not limited to Your mobile device being switched off, incorrect email address,
          network interruptions. You agree not to hold us liable for non-delivery for any information or alert or any
          loss suffered by You due to delay, distortion or failure of communication. You further acknowledge that You
          are responsible for the contact details shared with Us and shall update us on any change on Your contact
          details. We may use third party service providers to send alerts or communicate with You. You authorize the
          Company and Entities to override the Do not Disturb (<span className={`${styles.text1Bold}`}>“DND”</span>)
          settings to reach out to You over calls, SMS, emails and any other mode of communication.
        </div>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>13.</div>
          <div>PLATFORM ADVERTISING</div>
        </div>
        <div className={`${styles.text1}`}>
          The Platform and use the Services may involve display of third-party business and brand sponsored content
          including third party advertisements such as of our Business Partners and Service Providers, in-stream
          advertising and affiliate and other links which may re-direct You to other websites and online resources
          provided by third parties. We have no control over and are not responsible for the content of such
          websites/portals or for any information provided by or to third parties. You acknowledge and accept that we
          shall not be liable for any damage or loss caused to You or Your business, from Your use of any third-party
          websites, portals or information. You shall be solely responsible for always reading the terms and conditions
          and the privacy policy of such a third-party websites or portals, before using them.
        </div>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>14.</div>
          <div>INTELLECTUAL PROPERTY RIGHTS</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            We solely own all rights in the names, logos, works and trademarks in the name of ‘U Digital Content Private
            Limited’ and in relation to the Intellectual Property relating to the Platform. In addition, certain other
            names, logos, trademarks and works displayed on our Platform and in connection with the Services are the
            property of their respective owners and displayed subject to their permission. You may not use our
            Intellectual Property without our prior written permission; in particular You may not use the same in any
            manner that is commercial in nature or in any way likely to cause confusion about whether we are the source
            of or sponsor of or endorser of a product, service, or activity. Nothing in these Terms should be construed
            as granting, by implication, estoppel, or otherwise, the ownership or any license or right to use any of our
            Intellectual Property without our prior written permission in each instance. All goodwill generated from the
            use of our Intellectual Property will inure to our exclusive benefit.
          </li>
          <li>
            You agree to not copy, reproduce, republish, upload, post, transmit or distribute such Intellectual Property
            in any way or by any means, whether directly or indirectly (such as by assisting any other person to do so).
            We shall hold You accountable and liable for infringement of rights to Intellectual Property of the Company
            and the Entities.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>15.</div>
          <div>DISCLAIMER AND LIMITATION OF LIABILITY</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            We shall make sure that all material, content and information provided on the Platform, and/or via the
            portals through which Services are provided, is taken from sources believed to be reliable and all
            information is presented in good faith, however, we make no warranty or representation, either expressed or
            implied, in relation to the correctness, completeness, or accuracy of such information or material or
            content, or that they are in compliance with the provisions of the prevailing laws.
          </li>
          <li>
            Subject to applicable laws, the Company and the Entities (including employees and affiliates), shall not be
            liable for any incidental, special, punitive, consequential or similar damages or liabilities whatsoever
            arising out of or in connection with the Services, any performance or non-performance of Services provided
            by us, whether under contract, statute, strict liability or other theory even if we are advised of the
            possibility of such damages.
          </li>
          <li>
            You expressly agree that Your use of the Platform and/or Services is at Your sole and exclusive risk. The
            Services are provided on an “as is,” and “as available” basis, without warranties of any kind, including,
            without limitation: the availability, accuracy of content, information, and the warranties of
            merchantability, fitness for a particular purpose, and non-infringement. We expressly disclaim all such
            warranties. The entire risk as to the quality and timeliness of the information, and all Services provided
            by us is borne exclusively by You. We use public data and information provided to us by third parties in
            order to compile each “service” as such, we rely on the providers of this information for its accuracy and
            correctness. We make no warranty that the Services will meet Your requirements and expectations, or that the
            Services will be uninterrupted, timely, secure, or error free; nor do we make any warranty as to the results
            that may be obtained from the use of the Service or as to the accuracy or reliability of any information
            obtained through the Services. Any access to our Platform from territories where the contents are illegal is
            prohibited. If You access our Platform from a location outside India, You are responsible for compliance
            with all local laws.
          </li>
          <li>
            We do not warrant or guarantee the accuracy, correctness, reliability, suitability or at all in respect of
            any User submitted content or any other information made available to You through Your use. Any party which
            intends to use and/or rely upon any such content or any other information made available to You shall do so
            at its own risk and liability.
          </li>
          <li>
            We shall not be liable for losses that result from its failure to comply with these Terms that fall into the
            following categories:
            <ol style={{ listStyleType: 'lower-roman' }} className={`${styles.text1} mx-4`}>
              <li>consequential, indirect or special losses;</li>
              <li>loss of profits, income or revenue;</li>
              <li>loss of savings or anticipated savings, interest or production;</li>
              <li>loss of business or business benefits;</li>
              <li>loss of contracts (including conflicts, if any);</li>
              <li>loss of opportunity or expectations;</li>
              <li>loss of goodwill and/or reputation;</li>
              <li>loss of marketing and/or public relations time and/or opportunities;</li>
              <li>loss of data;</li>
              <li>loss of content; or</li>
              <li>
                loss of management or office time or any other losses howsoever arising and whether caused by tort
                (including negligence), breach of contract or otherwise, even if foreseeable.
              </li>
            </ol>
          </li>
          <li>
            Commentary and other materials posted on the Platform or provided by us are not intended to amount to advice
            on which reliance should be placed. We therefore disclaim all liability and responsibility arising from any
            reliance placed on such materials by any User of the Platform, or by anyone who may be informed of any of
            its contents. Further, responsibility for decisions taken on the basis of any information, suggestions and
            advice given to You shall remain solely with You.
          </li>
          <li>
            We shall have the right to take any other action with respect to any content or information on the Platform
            that we may deem necessary or appropriate in our sole discretion, if we believe that the same violates the
            Agreement between us, infringes any Intellectual Property rights or any other rights of an entity or any
            third-party, threatens the personal safety of the Users of the Platform or general public, creates any
            liability or threatens to create any liability for the Company.
          </li>
          <li>
            We shall have the sole and absolute right to remove and de-host any content
            posted/hosted/transmitted/shared/modified/uploaded shared by You if the content is found to be against the
            morals, philosophy, values, ethics, etc. of the Company or if the content is generally found to be
            inappropriate by the Company.
          </li>
          <li>
            We shall have a right to, subject to the terms of our Privacy Policy and any applicable law to: (i) disclose
            any information furnished by a User which includes but is not limited to personal information to any
            third-party who claims that content provided by You has violated any of the third-party rights including the
            Intellectual Property rights or privacy rights, provided that we shall send prior notification to such User
            if applicable and (ii) to take any appropriate legal action, including disclosing Your personal information
            or other information about You to any law enforcement body, government department, court of law, regulatory
            agency, tribunal or any law enforcement or government official, in respect of any suspected illegal or
            unauthorized use of the Platform and / or the Services.
          </li>
          <li>
            We shall, without prejudice to the above, fully cooperate with any law enforcement or regulatory authority
            or court order requesting or directing us to disclose the identity, or other information, of anyone posting
            or distributing any content on or through the Platform and / or the Services. We also reserve the right to
            access, review, monitor, display, read, preserve, store and disclose any information and any User
            Submissions as we reasonably believe is necessary or appropriate to:
            <ol style={{ listStyleType: 'lower-roman' }} className={`${styles.text1} mx-4`}>
              <li>satisfy any applicable law, rules, regulation, legal processor governmental request;</li>
              <li>investigate potential violations of and/or enforce the Agreement;</li>
              <li>detect, prevent, or otherwise address fraud, security or technical issues, or</li>
              <li>
                protect the rights, property or safety of any Users, the Company, the Entities or any third parties.
              </li>
            </ol>
          </li>
          <li>
            We assume no liability or responsibility to any User or third party for the performance or non-performance
            of the activities described in this Section.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>16.</div>
          <div>INDEMNIFICATION</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            Any time after creation of Your account on this Platform and even after the termination of Your Account, You
            agree to indemnify and hold harmless the Company and the Entities (including employees and affiliates), from
            and against any and all claims (including any third-party claims), costs, proceedings, demands, losses,
            damages, and expenses (including, without limitation, attorney’s fees, and legal costs) of any kind or
            nature, arising from or relating to:
            <ol style={{ listStyleType: 'lower-roman' }} className={`${styles.text1} mx-4`}>
              <li>any actual or alleged breach of these Terms by You or anyone using Your Account; and/or</li>
              <li>Your use of the Platform and/or the Services; and/or</li>
              <li>the content of Your uploaded information including User Submission; and/or</li>
              <li>
                the negotiation, performance or enforcement of any contract or agreement or understanding between You
                and any Person via the Platform or through the use of our Services.
              </li>
            </ol>
          </li>
          <li>
            The rights and remedies of the Company and the Entities (including employees and affiliates) in respect of
            any breach of these Terms, shall not be affected by any act or happening which otherwise might have affected
            such rights and remedies, except by a specific written waiver. The rights of indemnification stated herein
            shall be in addition to all other rights available to the Company and the Entities (including employees and
            affiliates) in law, equity or otherwise, including without limitation rights of specific performance,
            recession and restitution.
          </li>
          <li>
            The Users shall not pursue any claim, seek damages, reimbursements, or contribution from the Company and the
            Entities (including employees and affiliates), in respect of any claim costs, proceedings, demands, losses,
            damages, and expenses (including, without limitation, reasonable attorney’s fees, and legal costs) of any
            kind or nature, arising from or relating to the actions of other Users or from their dealings with other
            Users.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>17.</div>
          <div>Third Party Distribution Channels</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            The Company offers Software applications that may be made available through the Apple App Store, the Google
            Play Store or other distribution channels (
            <span className={`${styles.text1Bold}`}>“Distribution Channels”</span>). If you obtain such Software through
            a Distribution Channel, you may be subject to additional terms of the Distribution Channel. These Terms are
            between you and us only, and not with the Distribution Channel. To the extent that you utilize any other
            third party products and services in connection with your use of our Platform, you agree to comply with all
            applicable terms of any agreement for such third party products and services.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>18.</div>
          <div>ACCOUNT TERMINATION AND CANCELLATION</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            We reserve the right to suspend and/or terminate Your access to the Platform and/or the Services per our
            discretion in case of breach of these Terms or any applicable laws and / or fraudulent use, misuse or abuse
            of the Platform and/or the Services, including the harassment or abuse of other Users. For the avoidance of
            doubt, if Your use to the Platform has been terminated in accordance with these Terms, Your access to the
            Platform and / or Services (in whole or in part) as a User shall cease.
          </li>
          <li>
            Termination / Cancellation by User: You may terminate this Agreement and opt for cancellation of Services at
            any time by disabling or closing Your Account in accordance with this Agreement. You may contact us in order
            to do so, in case any support is required, in the manner provided under these Terms and we will assist You
            in closing Your Account. You may also contact us in the same manner, if You are seeking to close an Account
            on behalf of a deceased User. You may not close an Account if the Company determines, in its sole
            discretion, that such closure is being performed in an effort to evade a legal or regulatory investigation
            or to avoid paying any amounts lawfully due. You acknowledge and agree that if You cancel Your Account or
            Services, the access associated with that Account may be removed permanently from our database.
          </li>
          <li>
            In the event that You or the Company terminates this Agreement or Your access to the Platform and / or the
            Services, or deactivates or cancels Your Account, You remain liable for all activity conducted with or in
            connection with Your Account and for all amounts due / charges incurred by Your Account while it was in
            operation. The obligations and liabilities incurred by You prior to the termination, shall survive the
            termination of the Agreement for all the purposes.
          </li>
          <li>
            We reserve the right to store Your Account registration details and other information, after You close Your
            Account, for legal, business and regulatory compliance purposes, in accordance with our Privacy Policy and
            subject to applicable laws and regulations.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>19.</div>
          <div>GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            These Terms shall be governed by and construed in accordance with the laws of India and the courts of
            Mumbai, India shall have the exclusive jurisdiction.
          </li>
          <li>
            In case of any disputes or claims arises out of the Agreement, the Company and the User shall use all
            reasonable endeavors to resolve the matter amicably. If the dispute is not resolved within 30 (thirty) days
            of receipt of notice furnished by either party, then the dispute shall be referred to arbitration in
            accordance with these Terms and shall be finally settled in accordance with the Arbitration & Conciliation
            Act, 1996, then in effect which shall be deemed to have been incorporated herein, by binding arbitration.
            Each party shall appoint an arbitrator and the arbitrators so appointed by each party shall mutually appoint
            a third arbitrator who shall act as the presiding arbitrator. No officer, director, shareholder, employee,
            representative or relative or related party of any party may be nominated or appointed as an arbitrator. The
            place of arbitration shall be Mumbai, India and language of arbitration shall be English.
          </li>
          <li>
            Notwithstanding anything to the contrary herein, nothing in this Agreement shall prevent the Company from
            seeking interim or permanent injunctive relief or taking any other action in any court to enforce or protect
            its rights, including but not limited to any action for monetary damages and/or equitable relief, including
            but not limited to injunctive relief.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>20.</div>
          <div>Miscellaneous</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            <span className={`${styles.text1Bold}`}>Entire Agreement:</span> These Terms, Privacy Policy, and any other
            policies, code of conduct and general practices we publish and update from time to time (collectively
            referred to as<span className={`${styles.text1Bold}`}>“Agreement”</span>) will constitutes an entire
            Agreement between us and Users in relation to Your access to and use of the Platform and / or Services;
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>Waiver:</span> No delay or omission by the Company to exercise any
            right or power it has under this Agreement shall impair or be construed as a waiver of such right or power.
            A waiver by the Company of any breach or covenant shall not be construed to be a waiver of any succeeding
            breach or any other covenant. All waivers must be in writing and signed by the Company.
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>Severability:</span> If any term, provision, covenant, or condition
            of the Agreement is held by a court or regulatory body of competent jurisdiction to be invalid, void, or
            unenforceable, the rest of the Agreement shall remain in full force and effect and shall in no way be
            affected, impaired, or invalidated.
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>Force Majeure:</span> We shall not be liable whatsoever, for any
            delay or failure to perform resulting from causes outside our reasonable control, including but not limited
            to any failure to perform due to unforeseen circumstances or cause beyond our control such as acts of God,
            war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, epidemic,
            pandemic, lockdown, quarantine, network infrastructure failures, strikes, or shortages of transportation
            facilities, fuel, energy, labour or materials. (
            <span className={`${styles.text1Bold}`}>“Force Majeure Event”</span>)
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>Assignment:</span> You shall not assign, transfer or novate Your
            rights or obligations under these Terms to third parties, save without the written consent of the Company,
            which consent may be withheld for any or no reason at our sole discretion.
          </li>
          <li>
            <span className={`${styles.text1Bold}`}>Amendments:</span> We may amend any portion of the Agreement at any
            time by posting the revised version of this Agreement with an updated revision date. The changes will become
            effective, and shall be deemed accepted by You, the first time You use the Services after the initial
            posting of the revised Agreement and shall apply on a going-forward basis after the posting date. In the
            event that You do not agree with any such modification, Your sole and exclusive remedy is to terminate Your
            use of the Services and / or terminate Your Account. You agree that we shall not be liable to You or any
            third party as a result of any losses suffered by any modification or amendment of this Agreement. If the
            revised Agreement includes a material change, we will provide You with prior notice before the material
            change becomes effective. For this purpose, a “material change” means a significant change other than
            changes that (a) are to Your benefit; (b) are required to be made to comply with applicable laws and/or
            regulation; (c) relates to a new product or service made available to You; or (d) to otherwise clarify an
            existing term. As the Platform grows and offers new features, products, and services, we will need to make
            changes to this Agreement. You can always know when this Agreement was last changed by checking the ‘Last
            Updated’ date at the top of the Agreement.
          </li>
        </ol>
        <div className={`${styles.heading1} flex flex-row m-0 space-x-4`}>
          <div>21.</div>
          <div>GRIEVANCE REDRESSAL AND CONTACT DETAILS</div>
        </div>
        <ol style={{ listStyleType: 'number' }} className={`${styles.text1} mx-4`}>
          <li>
            In the event the User has any questions, suggestions or wishes to share their experience, comments and
            requests in respect of the Platform and Services (collectively{' '}
            <span className={`${styles.text1Bold}`}>“Feedback”</span>), such respective User may provide us Feedback by
            contacting us at{' '}
            <a className={`${styles.hyperLink}`} href="mailto:feedback@arrevoice.com" target="_blank" rel="noreferrer">
              feedback@arrevoice.com
            </a>
          </li>
          <li>
            If any User or aggrieved person (including persons acting on their behalf) have any complaints or grievances
            in respect of our Services and Platform, please contact our resident Grievance Officer, the name and details
            of whom are published on the Platform. Our Grievance Officer is the nodal contact to receive and acknowledge
            all complaints as well as receive and acknowledge any order, notice or direction of a court, the government,
            or its agency. The Grievance Officer shall acknowledge complaints by e-mail within 24 (twenty-four) hours
            and dispose of such complaint within a period of 15 (fifteen) days from the date of its receipt, provided
            that the Grievance Officer may request the complainant to furnish additional information for evidentiary and
            investigative purposes during this time. In the event we receive any complaint or grievance in relation to
            any content which we find is prima facie in the nature of any material which exposes the private area of
            such individual, shows such individual in full or partial nudity or shows or depicts such individual in any
            sexual act or conduct, or is in the nature of impersonation in an electronic form, including artificially
            morphed images of such individual, we shall take all reasonable and practicable measures to remove or
            disable access to such content in accordance with the law within 24 (twenty-four) hours from the receipt of
            a complaint.
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
};

export default TermsScreen;
